class CssVars {
    constructor() {
        this.elems = {};
        this.elems.$smoothHeight = $('.js-smooth-height');

        this.set('--calc-height', 'auto');
        this.update();
        this._addListeners();
    }

    update() {
        const { $smoothHeight } = this.elems;

        if($smoothHeight.length) {
            $smoothHeight.each(function() {
                const height = $(this).children().outerHeight(true);
                if(this.style.getPropertyValue('--calc-height') !== height) {
                    this.style.setProperty('--calc-height', height + 'px');
                }
            });
        }
    }

    updateNewElems() {
        this.elems.$smoothHeight = $('.js-smooth-height');

        const { $smoothHeight } = this.elems;

        if($smoothHeight.length) {
            $smoothHeight.each(function() {
                if(this.style.getPropertyValue('--calc-height')) return

                const height = $(this).children().outerHeight(true);
                if(this.style.getPropertyValue('--calc-height') !== height) {
                    this.style.setProperty('--calc-height', height + 'px');
                }
            });
        }
    }

    get(name, elem) {
        const target = elem ? elem : document.documentElement;

        const value = getComputedStyle(target).getPropertyValue(name);
        return parseInt(value, 10);
    }

    set(name, value, elem) {
        if(!value) return
        const target = elem ? elem : document.documentElement;

        if(typeof value === 'number') {
            target.style.setProperty(name, value + 'px');
        } else {
            target.style.setProperty(name, value);
        }
    }

    _addListeners() {
        $(window).on('resize', this._debounce(
            () => this.update(),
            300
        ));
    }

    _debounce(func, wait, immediate) {
        let timeout;

        return function executedFunction() {
            const context = this;
            const args = arguments;

            const later = function() {
                timeout = null;
                if(!immediate) func.apply(context, args);
            }

            const callNow = immediate && !setTimeout;

            clearTimeout(timeout);

            timeout = setTimeout(later, wait);

            if(callNow) func.apply(context, args);
        }
    }
}
